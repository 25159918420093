import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { ComputedPaymentTypeValues } from '@ads-bread/shared/bread/util';
import { RouteMachineServiceScreenProps } from '../RouteMachineService';
import IntroScreen, {
  IntroTitle,
  IntroSubHeading,
  formattingValues,
  PoweredBySubHeading,
} from '../pages/intro/Intro';
import { Button } from '../Button';
import { MarketingPromo } from '../MarketingPromo';
import { Disclaimers } from '../pages/intro/Disclaimers';
import { useFormattedNumber } from '../../lib/hooks/useFormattedNumber';
import { FCWithChildren } from '../../lib/types';
import { ButtonClickedEvents } from '../../lib/analytics';
import { useMerchantConfig } from '../MerchantContext';
import { InstallmentsIntroPageIcon } from '../svgs/InstallmentsIntroPageIcon';
import { SplitPayIntroPageIcon } from '../svgs/SplitPayIntroPageIcon';

const Card: FCWithChildren = ({ children }) => {
  return (
    <div
      className="z-0 flex flex-col bg-extra-light-grey w-full gap-6 rounded-lg border-theme-primary border-solid border-2 px-6 py-6 mb-6"
      data-testid=""
    >
      {children}
    </div>
  );
};

const CardDivider: FunctionComponent = () => {
  return <hr className="border-theme-primary border-solid border-[1px] mx-6" />;
};

interface InstallmentsCardContentProps {
  installmentsPaymentTypeValues: ComputedPaymentTypeValues;
}
const InstallmentsCardContent: FunctionComponent<InstallmentsCardContentProps> =
  ({ installmentsPaymentTypeValues }) => {
    const { disclosureSymbolInstallments } = useMerchantConfig();
    const { aprFormatter, formatCurrency } = useFormattedNumber();

    return (
      <div
        className="flex items-center gap-4"
        data-testid="installments-card-content"
      >
        <div className="">
          <InstallmentsIntroPageIcon aria-hidden="true" />
        </div>
        <div className="items-center justify-center text-lg leading-normal">
          <h3 className="font-bold mb-1">
            <FormattedMessage
              defaultMessage="Installments<sup>{disclosureSymbolInstallments}</sup>"
              description="Intro Streamlined card heading"
              values={{
                ...formattingValues,
                disclosureSymbolInstallments,
              }}
            />
          </h3>
          <h4 className="text-sm">
            <FormattedMessage
              defaultMessage="Starting at {minInterestRateBPS} APR"
              description="Intro Streamlined payment subheading"
              values={{
                minInterestRateBPS: aprFormatter(
                  installmentsPaymentTypeValues.minInterestRateBPS
                ),
              }}
            />
          </h4>
          <div className="flex-1 shrink-0 grow-0 flex-nowrap whitespace-nowrap border-theme-primary border-opacity-40 border-b-[1px] my-1 w-full"></div>
          <h4 className="text-sm">
            <FormattedMessage
              defaultMessage="Payments as low as {promoOfferAmount} per month"
              description="Intro Streamlined payment subheading"
              values={{
                promoOfferAmount: formatCurrency(
                  installmentsPaymentTypeValues.promoOfferAmount
                ),
              }}
            />
          </h4>
        </div>
      </div>
    );
  };

interface SplitPayCardContentProps {
  splitPayPaymentTypeValues: ComputedPaymentTypeValues;
}
const SplitPayCardContent: FunctionComponent<SplitPayCardContentProps> = ({
  splitPayPaymentTypeValues,
}) => {
  const { disclosureSymbolSplitpay } = useMerchantConfig();
  const { formatCurrency } = useFormattedNumber();
  return (
    <div
      className="flex items-center gap-4"
      data-testid="splitpay-card-content"
    >
      <div className="">
        <SplitPayIntroPageIcon aria-hidden="true" />
      </div>
      <div className="items-center justify-center text-lg leading-normal">
        <h3 className="font-bold mb-1">
          <FormattedMessage
            defaultMessage="SplitPay<sup>{disclosureSymbol}</sup>"
            description="Intro Streamlined card heading"
            values={{
              ...formattingValues,
              disclosureSymbol: disclosureSymbolSplitpay,
            }}
          />
        </h3>
        <h4 className="text-sm">
          <FormattedMessage
            defaultMessage="Always interest-free with 4 payments of {promoOfferAmount}"
            description="Intro Streamlined payment subheading"
            values={{
              promoOfferAmount: formatCurrency(
                splitPayPaymentTypeValues.promoOfferAmount
              ),
            }}
          />
        </h4>
      </div>
    </div>
  );
};

export const IntroStreamlinedScreen: FunctionComponent<RouteMachineServiceScreenProps> =
  (props) => {
    return (
      <IntroScreen {...props}>
        {({
          hasSplitpay,
          hasInstallments,
          sdkInstallmentsPaymentTypeValues,
          sdkSplitPayPaymentTypeValues,
          synthesizedSplitPayPaymentValues,
          useSyntheticPaymentTypeValues,
          exampleInstallmentsPaymentTypeValues,
        }) => {
          const splitPayPaymentTypeValues = useSyntheticPaymentTypeValues
            ? synthesizedSplitPayPaymentValues
            : sdkSplitPayPaymentTypeValues;

          const installmentsPaymentTypeValues = useSyntheticPaymentTypeValues
            ? exampleInstallmentsPaymentTypeValues
            : sdkInstallmentsPaymentTypeValues;

          return (
            <>
              <IntroTitle>
                <FormattedMessage
                  defaultMessage="Pay over time"
                  description="Intro screen heading"
                />
              </IntroTitle>

              <IntroSubHeading>
                {hasSplitpay && hasInstallments ? (
                  <FormattedMessage
                    defaultMessage="Make flexible payments with our pay-over-time plans"
                    description="ADS Intro screen subheading SxS"
                  />
                ) : hasSplitpay ? (
                  <FormattedMessage
                    defaultMessage="Split your purchase into 4 interest-free payments"
                    description="ADS Intro screen subheading splitpay only"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="With flexible monthly payment plans"
                    description="ADS Intro screen subheading installments only"
                  />
                )}
              </IntroSubHeading>

              <PoweredBySubHeading />

              <StreamlinedCards
                splitPayPaymentTypeValues={splitPayPaymentTypeValues}
                installmentsPaymentTypeValues={installmentsPaymentTypeValues}
              />

              <Button
                analyticsEventName={ButtonClickedEvents.GetStarted}
                onClick={props.forward}
              >
                <FormattedMessage
                  defaultMessage="Get started"
                  description="Intro screen button text"
                />
              </Button>

              {MarketingPromo && <MarketingPromo />}
              <Disclaimers
                hasInstallments={hasInstallments}
                hasSplitPay={hasSplitpay}
                installmentsPaymentTypeValues={
                  exampleInstallmentsPaymentTypeValues
                }
                splitPayPaymentTypeValues={splitPayPaymentTypeValues}
              />
            </>
          );
        }}
      </IntroScreen>
    );
  };

interface StreamlinedCardsProps {
  splitPayPaymentTypeValues: ComputedPaymentTypeValues | null;
  installmentsPaymentTypeValues: ComputedPaymentTypeValues | null;
}

const StreamlinedCards: FunctionComponent<StreamlinedCardsProps> = ({
  splitPayPaymentTypeValues,
  installmentsPaymentTypeValues,
}) => {
  return (
    <div>
      <div data-testid="intro-streamlined">
        {!!installmentsPaymentTypeValues && !!splitPayPaymentTypeValues && (
          <Card>
            <InstallmentsCardContent
              installmentsPaymentTypeValues={installmentsPaymentTypeValues}
            />
            <CardDivider />
            <SplitPayCardContent
              splitPayPaymentTypeValues={splitPayPaymentTypeValues}
            />
          </Card>
        )}

        {!!installmentsPaymentTypeValues && !splitPayPaymentTypeValues && (
          <Card>
            <InstallmentsCardContent
              installmentsPaymentTypeValues={installmentsPaymentTypeValues}
            />
          </Card>
        )}

        {!installmentsPaymentTypeValues && !!splitPayPaymentTypeValues && (
          <Card>
            <SplitPayCardContent
              splitPayPaymentTypeValues={splitPayPaymentTypeValues}
            />
          </Card>
        )}
      </div>
    </div>
  );
};
